import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-10-16T12:35:27+0000',
  },
  git: {
    branchName: 'task/new-change-tenant-demo',
    commitHash: '8b483011404167df605668ac2d42633035fee9de',
  },
};
